@import '../../Styles.module.scss';

.App {
    background-color: #000000;
    height: 100vh;
    animation: fadeBg 20s infinite linear;
}

.flegEnv {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 160px;
    height: 160px;
    transform: rotate(-90deg);
    background-image: url('../../fleg-polygon.png');
    background-size: 100%;
    background-repeat: no-repeat;
    @media screen and (max-width:640px) {
       z-index: 1000;
    }
}