@import '../../Styles.module.scss';
.mainContent {
    color: #fff;
    text-align: center;
    max-width: 740px;
    margin: 0 auto;
    padding: 8% 10px;
    @media screen and (max-width:640px) {
        padding: 10%;
    }

        h1 {
            font-size: 48px;
            text-shadow: 0 0 30px #000;
            @media screen and (max-width:640px) {
                font-size: 24px;
            }
        }
     p {
        font-size: 24px;
        @media screen and (max-width:640px) {
            font-size: 12px;
        }
     }
}

.waterPosition {
    transform: scale(1.3) translateX(-50%);
    position: fixed;
    left: 50%;
    bottom: -180px;
    transform-origin: left;
    animation: slideUpWater .5s forwards ease-out;
   
    @media screen and (max-width:640px) {
        animation: slideUpWaterMob .5s forwards ease-out;
        
    }
}

.alignMainBtns {
    display: flex;
    justify-content: center;
    margin: auto;
     > * {
        margin: 10px;  
     }
     
     @media screen and (max-width:640px) {
        flex-direction: column;
        max-width: 260px;
        
    }
}