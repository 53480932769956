.dialogContainer {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 100%;
    max-width: 300px;
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
    text-align: center;
    border-radius: 10px;
    border: none;
    background-image:url('../../paper-old.png');
}

.openWishDialog {
    animation: openWishDialog 3s forwards;
}



@keyframes openWishDialog {
    0% {max-width: 0; max-height: 0; opacity: 0; filter: blur(20px)};
    100% {max-width: 300px; max-height: 520px; opacity: 1; filter: blur(0)};
}