@import '../../Styles.module.scss';


.headerContainer {
    padding: 10px 20px;
    background-color: $headerBg;
}

.headerWidth {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

li {
    @extend .linkMenu;
    display: inline-block;
    margin: 0 10px;
    @media screen and (max-width:640px) {
        font-size: 12px;
        padding: 4px;
        margin: 0 4px;
    }
}

.iconImg {
    @include iconImg(0 10px 0 0);
}