@import '../../Styles.module.scss';

.panelContainer {
    min-height: 300px;
    max-width: 300px;
    position: absolute;
    z-index: 100;
    text-align: center;
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient($headerBg 0%, #ffffff40 120%); 
    border: 1px solid #ffffff20;
    backdrop-filter: blur(4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: center;
    border-radius: 10px;
    animation: smallMove 1s ease-in-out;
    
    @media screen and (max-width:640px) {
        max-width: 280px;
    }
    form {
        position: relative;
        @media screen and (max-width:640px) {
            flex-direction: column;
        }
        label span {
            font-size: 12px;
            color: #444;
        }
    }
}

.panelContent {
    padding: 30px;
    text-align: center;
    color: #fff;

    h2, p {
        margin: 0;
        padding: 0;
    }
}

.panelMove {
    animation: panelShow 2s ease-in-out forwards;
}

.coverForm {
    background-color: goldenrod;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: rewindFade 2s forwards;
}

.disableForm {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(4px);
    background-color: transparent;
    &::after {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px;
        content: 'need metamask';
        background-color: $headerBg;
        color: #fff;
        font-size: .8rem;
        border-radius: 4px;
        
    }
}