.canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 66%;
}

.wellContainer {
    overflow: hidden;
    box-shadow: 0 10px 20px #000;
    margin: 0 auto;
    width: 600px;
    height: 600px;
    background-image: url('./.././../well-hole.png');
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center center;
    border-radius: 50%;
    cursor: pointer; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width:640px) {
        width: 80vw;
        height: 80vw;
    }
}