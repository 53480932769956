@import '../../Styles.module.scss';

.dialogWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: #00000050;
}

.contentInstallMeta {
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
}

.metaIcon {
    width: 140px;
}

.dialogEl {
    margin: 20px auto;
    text-align: center;
}