

@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');



$borderRadius: 4px;
$primaryColorBtn: #694CFF;
$primaryColorBtnHover: #8269FF; 
$headerBg: #030920;
$linkColorMenu: #e3e3e3;
$panelBg: #3F2F93;

button, span, p, h1, h2, h3, h4, h5, h6, li, a, label {
    font-family: 'Zilla Slab', serif;
    letter-spacing: 1px;
}
a { 
    text-decoration: none;
    color: #fff;
}
body {
    background-image: url('./dark-forest-nature.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  
.Primary {
    background-color: $primaryColorBtn;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0 0 10px #ffffff80;
    border: none;
    border-radius: $borderRadius;
    box-shadow: 0 0 10px #03092020;
    cursor: pointer;
    transition: all .2s;
    
    &.changes {
        @media screen and (max-width:640px) {
            font-size: 12px;
        }
    }
    &:hover {
        background-image: linear-gradient($primaryColorBtn, $primaryColorBtnHover);
    }
}

.fullWidth {
    width: 100%;
}

.Secoundary {
    background-color: #e3e3e3;
    padding: 10px 20px;
    color: #282c34;
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0 0 10px #ffffff80;
    border: none;
    border-radius: $borderRadius;
    box-shadow: 0 0 10px #03092020;
    cursor: pointer;
    transition: all .2s;
    
    &:hover {
        background-color: #ddd;
    } 
}

.DisabledBtn { 
    cursor: default;
  }

.iconBtn {
    background-color: #aaa;
    padding: 6px;
    color: #282c34;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}


 .linkMenu {
    color: $linkColorMenu;
    font-size: 14px;
    padding: 10px;
    transition: all .3s;
    font-weight: 500;

        &:hover {
            color: $primaryColorBtnHover;     
        }
}

.dialogContentContainer {
    padding: 20px;
    text-align: center;
     
    p, h3 {
        color: #222222;
        text-shadow: 1px 1px 1px rgba($color: #fff, $alpha: .4);
     }
}

.backgroundDialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient($headerBg 0%, #e6646500 120%); 
    animation: blueBg 2s forwards;
}

.formGroup {
    padding-bottom: 20px;
    text-align: left;
    width: 100%;
    display: flex;

    @media screen and (max-width:640px) {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    label {
        font-size: 12px;
        font-weight: 100;
        color: #fff;
        background-color: #f3f3f3;
        border-radius: 4px 0px 0px 4px;
        border-right: 1px solid #e3e3e3;
      
        img {
            width: 20px;
            padding: 11px;
            vertical-align: middle;
        }
    }
    input, select {
        line-height: 22px;
        padding: 10px;
        border-radius: 0px 4px 4px 0px;
        border: none;
        font-weight: 600;
        background-color: #fff;
        @media screen and (max-width:640px) {
            width: 70vw;
        }
    }
}

// Mixins

@mixin iconImg($margin) {
    height: auto;
    max-height: 24px;
    vertical-align: middle;
    margin: $margin;
}

.flexCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// Animations

@keyframes fadeBg {
    0% {
        background-color: #000000dd;
    }
    25% {
        background-color: #00000070;
    }
    50% {
        background-color: #00000040;
    }
    75% {
        background-color: #00000070;
    }
    100% {
        background-color: #000000dd;
    }
  }


  @keyframes slideUpWater {
    0% {
        bottom: -240px;
    }

    100% {
        bottom: -100px;
    }
  }
  @keyframes slideUpWaterMob {
    0% {
        bottom: -240px;
    }

    100% {
        bottom: 10px;
    }
  }
  
  
@keyframes blueBg {
    0% { backdrop-filter: blur(0);};
    100% {backdrop-filter: blur(2px);};
}

@keyframes panelShow {
    0% { clip-path: circle(75%);};
    100% {clip-path: circle(5%); transform: translate(-50%, -56%) scale(0)};
    
}

@keyframes rewindFade {
    0% { opacity: 0;};
    80% { opacity: 1;};
    100% {opacity: 1};
    
}

@keyframes smallMove {
    0% { top: 40%;};
    50% {top: 60%;};
    100% {top: 50%};
}